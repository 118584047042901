
html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
.error-message {
    color: red;
  }

  .calmag-logo{
    margin-left:13px
  }
  .route-load-in-progress {
    position: fixed;
    z-index: 10001;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.7);
  }

  .logo-container {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    margin: 0 auto; /* Center the container itself horizontally */
    max-width: 100%; /* Ensure the image doesn't exceed its container width */
}

.radio-group-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}